<template>
    <div id="container">
        <div id="contents">
            <div class="contents_header">
                <div class="MenuBox">
                    <div class="m1dep">공통코드 관리</div>
                    <div class="m2dep">
                        <ul>
                            <li class="over" @click="$router.push({name:'ADMCODE01M01'})">공통 분류코드 등록/수정</li>
                            <li class="over" @click="$router.push({name:'ADMCODE02M01'})">공통 코드 등록/수정</li>
                            <li class="on">공통 상세코드 등록/수정</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="contents_body">
                <div class="Board">
                    <table class="Board_type2">
                        <colgroup>
                        </colgroup>
                        <thead>
                        </thead>
                        <tbody>									
                            <tr>
                                <td colspan="2">
                                    <div class="admitem">
                                        <div class="left"><span>업무중분류코드명</span>{{data.codeIdNm}}</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <div class="admitem">
                                        <div class="left"><span>상세코드</span>{{data.code}}</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <div class="admitem">
                                        <div class="left"><span>상세코드명</span>{{data.codeNm}}</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <div class="admitem">
                                        <div class="left"><span>상세코드설명</span>{{data.codeDc}}</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <div class="admitem">
                                        <div class="left"><span>사용여부</span>{{data.useAt}}</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <!--버튼영역-->
                <div class="BtnArea">
                    <div class="Btninner">
                        <span class="btn list" @click="btnList">목록</span>
						<span class="btn list" @click="btnModify">수정</span>
						<span class="btn list" @click="btnDel">삭제</span>
                    </div>
                </div>
                <!--//버튼영역-->
            </div><!--//contents_body-->
        </div>
        <!--//contents-->
    </div>
    <!--//container-->
</template>
<script>
    export default {
        data() {
            return {
				data : {},
            }
        },
        beforeCreate() { // console.log('beforeCreate');
        },
        created() { // console.log('created');
        },
        beforeMount() { // console.log('beforeMount');
        },
        mounted() { // console.log('mounted');
            this.getDetail();
        },
        beforeUpdate() { // console.log('beforeUpdate');
        },
        updated() { // console.log('update');
        },
        beforeDestroy() { // console.log('beforeDestroy');
        },
        destroyed() { // console.log('destroyed');
        },
        methods: {
            getDetail() {

                var param = {
                    codeId: this.$route.params.codeId,
                    code: this.$route.params.code,
                };
                
                this.$.httpPost('/api/code/adm/getDetailCodeDetail', param)
                    .then(res => {
                        //console.log(res.data);
						this.data = res.data;
                    })
                    .catch(err => {
                        // console.log('=============================');
                        // console.log(err);
                        // console.log(err.response);
                        // console.log('=============================');
                        alert(err.response.data.error_description);
                    });
            },
            btnDel() {

                if(!confirm('삭제하시겠습니까?')) {
                    return;
                }

                var param = {
                    codeId: this.$route.params.codeId,
                    code: this.$route.params.code
                };

                // console.log("param", param);
                this.$.httpPost('/api/code/adm/delDetailCode', param)
                    .then(() => {
                        // console.log(res.data);
                        this.$router.push({name:'ADMCODE03M01'});
                    })
                    .catch(err => {
                        // console.log('=============================');
                        // console.log(err);
                        // console.log(err.response);
                        // console.log('=============================');
                        alert(err.response.data.error_description);
                    });
            },
            btnList() {
                this.$router.push({name:'ADMCODE03M01'});
            },
            btnModify() {
                 var page = {
                    name : 'ADMCODE03M03',
                    params : {
                        codeId : this.$route.params.codeId,
                        code : this.$route.params.code
                    }
                }
                this.$router.push(page);
            },

        }
    }
</script>